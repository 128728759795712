<div style="margin:30px" class="tableContainer">
  <p>{{'planner.startPlanner' | translate}}</p>
  <div class="input-box d-flex align-items-center p-3">
    <div *ngIf="base64Image" class="card mb-3 me-3 mt-1" style="width: 8rem;">
      <img [src]="base64Image" class="card-img-top" alt="Image">
      <button
        class="btn btn-sm btn-outline-primary position-absolute top-0 start-100 translate-middle"
        (click)="removeImage()"
        aria-label="Remove image"
        style="border-radius: 50%;"
      >X
      </button>
    </div>
    <input type="file" accept=".jpg,.png" (change)="onFileSelected($event)" #fileInput style="display: none;"/>
    <button class="btn btn-link p-0 me-2" (click)="fileInput.click()" style="text-decoration: none;" aria-label="Attach file"><i class="fa fa-paperclip" style="font-size: 1.5em;"></i></button>
    <input [(ngModel)]="humanMessage" class="form-control me-2" placeholder="Type your message here..." (keydown.enter)="sendMessage()" style="flex-grow: 1;"/>

    <button class="btn sendMessageButton" (click)="sendMessage()"  style="display: flex; align-items: center;">
      <i class="bi  mr-1 bi-send sendMessageIcon"  style="cursor: pointer" data-bs-toggle="tooltip" data-bs-placement="top" title="{{'chat.send' | translate}}" ></i>
    </button>
  </div>
</div>

export const environment = {
  production: false,
  keycloakClientId:'frontend_dev',
  keycloakUrl: 'https://stg.arrowheadcopilot.online/keycloak/',
  keycloakRealm:'copilot',
  serviceUrl: "https://stg.arrowheadcopilot.online/v1",
  documentServiceUrl: "/documents",
  chatServiceUrl: "/chats",
  plannerServiceUrl: "/planner",
  pluginServiceUrl: "/config/plugins",
  templateServiceUrl: "/config/templates",
  ragServiceUrl: "/rag",
  exitPeriod: 3000000,
  logo: "Logo.png",
  chatLogo:"Logo.svg",
  baseHref: '/'
}
export enum AvailableUserChoices {
  NONE = 'NONE',
  RAG_SILENT = 'RAG_SILENT',
  RAG_EDITOR = 'RAG_EDITOR',
  REACT_SILENT = 'REACT_SILENT',
  REACT_EDITOR = 'REACT_EDITOR',
  USER_CANCEL = 'USER_CANCEL',
  PLANNER_SILENT = 'PLANNER_SILENT',
  PLANNER_EDITOR = 'PLANNER_EDITOR'
}
export enum runMode{
  SILENT='SILENT',
  AUTO='AUTO',
  MANUAL='MANUAL'
}
export enum documentTypeEnum{
  UPLOADED='UPLOADED',
  CUSTOM ='CUSTOM'
}
export enum MessageStatus {
  DEFAULT = 'DEFAULT',
  MESSAGE_GENERATION_PENDING = 'MESSAGE_GENERATION_PENDING',
  USER_CHOICE_PENDING= 'USER_CHOICE_PENDING',
  USER_CHOICE_FINALIZED= 'USER_CHOICE_FINALIZED',
  TOOL_EXECUTION_PENDING= 'TOOL_EXECUTION_PENDING',
  MESSAGE_FINALIZED= 'MESSAGE_FINALIZED'

}
export enum ToolingType {
  RAG_AGENT = 'RAG_AGENT',
  REACT_AGENT = 'REACT_AGENT',
  PLANNER_AGENT = 'PLANNER_AGENT'
}
export enum ToolingTypeToLink {
  RAG_AGENT = 'rag',
  PLANNER_AGENT = 'planner'
}

export enum MessageType {
  HUMAN_MESSAGE = 'HUMAN_MESSAGE',
  ASSISTANT_MESSAGE = 'ASSISTANT_MESSAGE',
  TOOL_SELECTOR = 'TOOL_SELECTOR',
  PLACEHOLDER='PLACEHOLDER',
  REDACTED='REDACTED'
}
export enum DocumentStatus {
  CREATED = 'CREATED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}
export enum AUTHMETHODS {
  NONE = 'none',
  MTLS = 'mtls',
  AUTH_TOKEN = 'auth_token',

}
export enum STEP_TYPE {
  // For user intent steps
  USER_INTENT = "USER_INTENT",
  PLACEHOLDER = "PLACEHOLDER",
  FINAL_ANSWER = "FINAL_ANSWER",
  ANSWER_GENERATION = "ANSWER_GENERATION",
  // For Retrieval and Generation steps in RAG (Retrieval-Augmented Generation)
  RETRIEVAL = "RETRIEVAL",
  LLM_ANSWER = "LLM_ANSWER",
  // For Planner related steps
  AVAILABLE_PLUGINS_INFO = "AVAILABLE_PLUGINS_INFO",
  PLUGIN_ACTION = "PLUGIN_ACTION",
  PLUGIN_ACTION_RESPONSE = "PLUGIN_ACTION_RESPONSE",
  INITIAL_PLAN = "INITIAL_PLAN",
  REVISED_PLAN = "REVISED_PLAN",
  TASK = "TASK",
  OUTCOME = "OUTCOME",
  TASK_OUTCOME = "TASK_OUTCOME",
  USER_REVIEW = "USER_REVIEW",

}
export enum STEP_STATUS {
  CREATED = "CREATED",
  IN_PROGRESS = "IN_PROGRESS",
  USER_PENDING = "USER_PENDING",
  USER_FINALIZED = "USER_FINALIZED",
  AUTO_FINALIZED = "AUTO_FINALIZED",
  CANCELLED = "CANCELLED"
}
export enum VOTE_OPTION {
  THUMBS_DOWN = "THUMBS_DOWN",
  THUMBS_UP = "THUMBS_UP",
}
export enum documentPartitionType {
 FIGURE="figure",
 TEXT="text",
 TABLE="table",
}

export enum toolingRunMode {
  MANUAL="MANUAL",
  AUTO="AUTO",
  SILENT="SILENT"
}

import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {lastValueFrom} from "rxjs";
import {NotificationService} from "../../services/notification.service";
import {PlannerRepository} from "../../repositories/planner.repository";
import {Utilities} from "../../utilities/utilities";
import {Router} from "@angular/router";

@Component({
  selector: 'app-plan-page',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule
  ],
  templateUrl: './plan-page.component.html',
  styleUrl: './plan-page.component.css'
})
export class PlanPageComponent implements OnInit{
  base64Image: string = "";
  selectedFile: File | null = null;
  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  humanMessage: string = "";

  constructor(private plannerRepository: PlannerRepository, private notificationService: NotificationService, private cdr: ChangeDetectorRef, private router: Router) {
  }

  async ngOnInit(): Promise<void> {
    console.log(this.getPlanners())
  }

  async getPlanners() {
    let planners = await lastValueFrom(
      this.plannerRepository.getPlanners())
    return planners
  }

  onFileSelected(event: Event): void {

    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      this.selectedFile = input.files[0];
      const file = input.files[0];
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        const reader = new FileReader();
        reader.onload = () => {
          this.base64Image = reader.result as string;
          this.cdr.detectChanges();
        };
        reader.readAsDataURL(this.selectedFile);
        this.selectedFile = file;
      } else {
        this.notificationService.showError("Error", "chat.errorFileUpload");
      }
    }
  }

  removeImage() {
    this.base64Image = "";
    this.selectedFile = null;
    if (this.fileInput) {
      this.fileInput.nativeElement.value = "";
    }
  }

  async sendMessage() {

    try {
      let newPlanner;
      if (this.selectedFile) {
        newPlanner = await lastValueFrom(
          await this.plannerRepository.newPlanner(this.humanMessage, this.selectedFile)
        );
      } else {
        newPlanner = await lastValueFrom(await this.plannerRepository.newPlanner(this.humanMessage));
      }
      await new Promise(resolve => setTimeout(resolve, 200));
      console.log(newPlanner);
      await this.router.navigate([`/planner/${newPlanner.toolingId}`]);
    } catch (error) {
      console.error("Nem sikerült új plannert létrehozni:", error);
    } finally {
      this.base64Image = "";
      this.selectedFile = null;
      if (this.fileInput) {
        this.fileInput.nativeElement.value = "";
      }
    }
  }
}
